import { tuiCreateToken, tuiProvideOptions, tuiCreateTokenFromFactory, AbstractTuiController, TuiDestroyService, tuiWatch } from '@taiga-ui/cdk';
import * as i0 from '@angular/core';
import { forwardRef, Directive, Input, NgModule, InjectionToken, ChangeDetectorRef } from '@angular/core';
import { TUI_TEXTFIELD_APPEARANCE } from '@taiga-ui/core/tokens';
import { merge, NEVER } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
class TuiTextfieldController {
  constructor(change$, options, legacyAppearance, appearanceDirective, cleanerDirective, customContentDirective, iconDirective, iconLeftDirective, labelOutsideDirective, sizeDirective, prefixDirective, postfixDirective, fillerDirective) {
    this.change$ = change$;
    this.options = options;
    this.legacyAppearance = legacyAppearance;
    this.appearanceDirective = appearanceDirective;
    this.cleanerDirective = cleanerDirective;
    this.customContentDirective = customContentDirective;
    this.iconDirective = iconDirective;
    this.iconLeftDirective = iconLeftDirective;
    this.labelOutsideDirective = labelOutsideDirective;
    this.sizeDirective = sizeDirective;
    this.prefixDirective = prefixDirective;
    this.postfixDirective = postfixDirective;
    this.fillerDirective = fillerDirective;
  }
  get appearance() {
    return this.appearanceDirective.appearance || this.legacyAppearance;
  }
  get cleaner() {
    return this.cleanerDirective.cleaner;
  }
  get customContent() {
    return this.customContentDirective.customContent || '';
  }
  get icon() {
    return this.iconDirective.icon;
  }
  get iconLeft() {
    return this.iconLeftDirective.iconLeft;
  }
  get labelOutside() {
    return this.labelOutsideDirective.labelOutside;
  }
  get size() {
    return this.sizeDirective.size;
  }
  get prefix() {
    return this.prefixDirective.prefix;
  }
  get postfix() {
    return this.postfixDirective.postfix;
  }
  get filler() {
    return this.fillerDirective.filler;
  }
}

/** Default values for primitive textfield options */
const TUI_TEXTFIELD_DEFAULT_OPTIONS = {
  iconCleaner: 'tuiIconClose',
  hintOnDisabled: false
};
/**
 * Default parameters for textfield
 */
const TUI_TEXTFIELD_OPTIONS = tuiCreateToken(TUI_TEXTFIELD_DEFAULT_OPTIONS);
function tuiTextfieldOptionsProvider(options) {
  return tuiProvideOptions(TUI_TEXTFIELD_OPTIONS, options, TUI_TEXTFIELD_DEFAULT_OPTIONS);
}

// TODO: rename to TUI_TEXTFIELD_APPEARANCE in v4
const TUI_TEXTFIELD_APPEARANCE_DIRECTIVE = tuiCreateTokenFromFactory(() => new TuiTextfieldAppearanceDirective());
class TuiTextfieldAppearanceDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    // it's an empty string by default for backward compatibility
    // (see comment https://github.com/taiga-family/taiga-ui/pull/3007#issuecomment-1315179508)
    this.appearance = '';
  }
}
TuiTextfieldAppearanceDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiTextfieldAppearanceDirective_BaseFactory;
  return function TuiTextfieldAppearanceDirective_Factory(t) {
    return (ɵTuiTextfieldAppearanceDirective_BaseFactory || (ɵTuiTextfieldAppearanceDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldAppearanceDirective)))(t || TuiTextfieldAppearanceDirective);
  };
})();
TuiTextfieldAppearanceDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiTextfieldAppearanceDirective,
  selectors: [["", "tuiTextfieldAppearance", ""]],
  inputs: {
    appearance: [0, "tuiTextfieldAppearance", "appearance"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_TEXTFIELD_APPEARANCE_DIRECTIVE,
    useExisting: forwardRef(() => TuiTextfieldAppearanceDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldAppearanceDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiTextfieldAppearance]',
      providers: [{
        provide: TUI_TEXTFIELD_APPEARANCE_DIRECTIVE,
        useExisting: forwardRef(() => TuiTextfieldAppearanceDirective)
      }]
    }]
  }], null, {
    appearance: [{
      type: Input,
      args: ['tuiTextfieldAppearance']
    }]
  });
})();
const TUI_TEXTFIELD_CLEANER = tuiCreateTokenFromFactory(() => new TuiTextfieldCleanerDirective());
class TuiTextfieldCleanerDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.cleaner = false;
  }
}
TuiTextfieldCleanerDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiTextfieldCleanerDirective_BaseFactory;
  return function TuiTextfieldCleanerDirective_Factory(t) {
    return (ɵTuiTextfieldCleanerDirective_BaseFactory || (ɵTuiTextfieldCleanerDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldCleanerDirective)))(t || TuiTextfieldCleanerDirective);
  };
})();
TuiTextfieldCleanerDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiTextfieldCleanerDirective,
  selectors: [["", "tuiTextfieldCleaner", ""]],
  inputs: {
    cleaner: [0, "tuiTextfieldCleaner", "cleaner"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_TEXTFIELD_CLEANER,
    useExisting: forwardRef(() => TuiTextfieldCleanerDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldCleanerDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiTextfieldCleaner]',
      providers: [{
        provide: TUI_TEXTFIELD_CLEANER,
        useExisting: forwardRef(() => TuiTextfieldCleanerDirective)
      }]
    }]
  }], null, {
    cleaner: [{
      type: Input,
      args: ['tuiTextfieldCleaner']
    }]
  });
})();
const TUI_TEXTFIELD_CUSTOM_CONTENT = tuiCreateTokenFromFactory(() => new TuiTextfieldCustomContentDirective());
class TuiTextfieldCustomContentDirective extends AbstractTuiController {}
TuiTextfieldCustomContentDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiTextfieldCustomContentDirective_BaseFactory;
  return function TuiTextfieldCustomContentDirective_Factory(t) {
    return (ɵTuiTextfieldCustomContentDirective_BaseFactory || (ɵTuiTextfieldCustomContentDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldCustomContentDirective)))(t || TuiTextfieldCustomContentDirective);
  };
})();
TuiTextfieldCustomContentDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiTextfieldCustomContentDirective,
  selectors: [["", "tuiTextfieldCustomContent", ""]],
  inputs: {
    customContent: [0, "tuiTextfieldCustomContent", "customContent"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_TEXTFIELD_CUSTOM_CONTENT,
    useExisting: forwardRef(() => TuiTextfieldCustomContentDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldCustomContentDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiTextfieldCustomContent]',
      providers: [{
        provide: TUI_TEXTFIELD_CUSTOM_CONTENT,
        useExisting: forwardRef(() => TuiTextfieldCustomContentDirective)
      }]
    }]
  }], null, {
    customContent: [{
      type: Input,
      args: ['tuiTextfieldCustomContent']
    }]
  });
})();
const TUI_TEXTFIELD_FILLER = tuiCreateTokenFromFactory(() => new TuiTextfieldFillerDirective());
class TuiTextfieldFillerDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.filler = '';
  }
}
TuiTextfieldFillerDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiTextfieldFillerDirective_BaseFactory;
  return function TuiTextfieldFillerDirective_Factory(t) {
    return (ɵTuiTextfieldFillerDirective_BaseFactory || (ɵTuiTextfieldFillerDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldFillerDirective)))(t || TuiTextfieldFillerDirective);
  };
})();
TuiTextfieldFillerDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiTextfieldFillerDirective,
  selectors: [["", "tuiTextfieldFiller", ""]],
  inputs: {
    filler: [0, "tuiTextfieldFiller", "filler"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_TEXTFIELD_FILLER,
    useExisting: forwardRef(() => TuiTextfieldFillerDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldFillerDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiTextfieldFiller]',
      providers: [{
        provide: TUI_TEXTFIELD_FILLER,
        useExisting: forwardRef(() => TuiTextfieldFillerDirective)
      }]
    }]
  }], null, {
    filler: [{
      type: Input,
      args: ['tuiTextfieldFiller']
    }]
  });
})();
const TUI_TEXTFIELD_ICON = tuiCreateTokenFromFactory(() => new TuiTextfieldIconDirective());
class TuiTextfieldIconDirective extends AbstractTuiController {}
TuiTextfieldIconDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiTextfieldIconDirective_BaseFactory;
  return function TuiTextfieldIconDirective_Factory(t) {
    return (ɵTuiTextfieldIconDirective_BaseFactory || (ɵTuiTextfieldIconDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldIconDirective)))(t || TuiTextfieldIconDirective);
  };
})();
TuiTextfieldIconDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiTextfieldIconDirective,
  selectors: [["", "tuiTextfieldIcon", ""]],
  inputs: {
    icon: [0, "tuiTextfieldIcon", "icon"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_TEXTFIELD_ICON,
    useExisting: forwardRef(() => TuiTextfieldIconDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldIconDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiTextfieldIcon]',
      providers: [{
        provide: TUI_TEXTFIELD_ICON,
        useExisting: forwardRef(() => TuiTextfieldIconDirective)
      }]
    }]
  }], null, {
    icon: [{
      type: Input,
      args: ['tuiTextfieldIcon']
    }]
  });
})();
const TUI_TEXTFIELD_ICON_LEFT = tuiCreateTokenFromFactory(() => new TuiTextfieldIconLeftDirective());
class TuiTextfieldIconLeftDirective extends AbstractTuiController {}
TuiTextfieldIconLeftDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiTextfieldIconLeftDirective_BaseFactory;
  return function TuiTextfieldIconLeftDirective_Factory(t) {
    return (ɵTuiTextfieldIconLeftDirective_BaseFactory || (ɵTuiTextfieldIconLeftDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldIconLeftDirective)))(t || TuiTextfieldIconLeftDirective);
  };
})();
TuiTextfieldIconLeftDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiTextfieldIconLeftDirective,
  selectors: [["", "tuiTextfieldIconLeft", ""]],
  inputs: {
    iconLeft: [0, "tuiTextfieldIconLeft", "iconLeft"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_TEXTFIELD_ICON_LEFT,
    useExisting: forwardRef(() => TuiTextfieldIconLeftDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldIconLeftDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiTextfieldIconLeft]',
      providers: [{
        provide: TUI_TEXTFIELD_ICON_LEFT,
        useExisting: forwardRef(() => TuiTextfieldIconLeftDirective)
      }]
    }]
  }], null, {
    iconLeft: [{
      type: Input,
      args: ['tuiTextfieldIconLeft']
    }]
  });
})();
const TUI_TEXTFIELD_LABEL_OUTSIDE = tuiCreateTokenFromFactory(() => new TuiTextfieldLabelOutsideDirective());
class TuiTextfieldLabelOutsideDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.labelOutside = false;
  }
}
TuiTextfieldLabelOutsideDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiTextfieldLabelOutsideDirective_BaseFactory;
  return function TuiTextfieldLabelOutsideDirective_Factory(t) {
    return (ɵTuiTextfieldLabelOutsideDirective_BaseFactory || (ɵTuiTextfieldLabelOutsideDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldLabelOutsideDirective)))(t || TuiTextfieldLabelOutsideDirective);
  };
})();
TuiTextfieldLabelOutsideDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiTextfieldLabelOutsideDirective,
  selectors: [["", "tuiTextfieldLabelOutside", ""]],
  inputs: {
    labelOutside: [0, "tuiTextfieldLabelOutside", "labelOutside"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_TEXTFIELD_LABEL_OUTSIDE,
    useExisting: forwardRef(() => TuiTextfieldLabelOutsideDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldLabelOutsideDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiTextfieldLabelOutside]',
      providers: [{
        provide: TUI_TEXTFIELD_LABEL_OUTSIDE,
        useExisting: forwardRef(() => TuiTextfieldLabelOutsideDirective)
      }]
    }]
  }], null, {
    labelOutside: [{
      type: Input,
      args: ['tuiTextfieldLabelOutside']
    }]
  });
})();
const TUI_TEXTFIELD_POSTFIX = tuiCreateTokenFromFactory(() => new TuiTextfieldPostfixDirective());
class TuiTextfieldPostfixDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.postfix = '';
  }
}
TuiTextfieldPostfixDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiTextfieldPostfixDirective_BaseFactory;
  return function TuiTextfieldPostfixDirective_Factory(t) {
    return (ɵTuiTextfieldPostfixDirective_BaseFactory || (ɵTuiTextfieldPostfixDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldPostfixDirective)))(t || TuiTextfieldPostfixDirective);
  };
})();
TuiTextfieldPostfixDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiTextfieldPostfixDirective,
  selectors: [["", "tuiTextfieldPostfix", ""]],
  inputs: {
    postfix: [0, "tuiTextfieldPostfix", "postfix"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_TEXTFIELD_POSTFIX,
    useExisting: forwardRef(() => TuiTextfieldPostfixDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldPostfixDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiTextfieldPostfix]',
      providers: [{
        provide: TUI_TEXTFIELD_POSTFIX,
        useExisting: forwardRef(() => TuiTextfieldPostfixDirective)
      }]
    }]
  }], null, {
    postfix: [{
      type: Input,
      args: ['tuiTextfieldPostfix']
    }]
  });
})();
const TUI_TEXTFIELD_PREFIX = tuiCreateTokenFromFactory(() => new TuiTextfieldPrefixDirective());
class TuiTextfieldPrefixDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.prefix = '';
  }
}
TuiTextfieldPrefixDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiTextfieldPrefixDirective_BaseFactory;
  return function TuiTextfieldPrefixDirective_Factory(t) {
    return (ɵTuiTextfieldPrefixDirective_BaseFactory || (ɵTuiTextfieldPrefixDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldPrefixDirective)))(t || TuiTextfieldPrefixDirective);
  };
})();
TuiTextfieldPrefixDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiTextfieldPrefixDirective,
  selectors: [["", "tuiTextfieldPrefix", ""]],
  inputs: {
    prefix: [0, "tuiTextfieldPrefix", "prefix"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_TEXTFIELD_PREFIX,
    useExisting: forwardRef(() => TuiTextfieldPrefixDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldPrefixDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiTextfieldPrefix]',
      providers: [{
        provide: TUI_TEXTFIELD_PREFIX,
        useExisting: forwardRef(() => TuiTextfieldPrefixDirective)
      }]
    }]
  }], null, {
    prefix: [{
      type: Input,
      args: ['tuiTextfieldPrefix']
    }]
  });
})();
const TUI_TEXTFIELD_SIZE = tuiCreateTokenFromFactory(() => new TuiTextfieldSizeDirective());
class TuiTextfieldSizeDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.size = 'l';
  }
}
TuiTextfieldSizeDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiTextfieldSizeDirective_BaseFactory;
  return function TuiTextfieldSizeDirective_Factory(t) {
    return (ɵTuiTextfieldSizeDirective_BaseFactory || (ɵTuiTextfieldSizeDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldSizeDirective)))(t || TuiTextfieldSizeDirective);
  };
})();
TuiTextfieldSizeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiTextfieldSizeDirective,
  selectors: [["", "tuiTextfieldSize", ""]],
  inputs: {
    size: [0, "tuiTextfieldSize", "size"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_TEXTFIELD_SIZE,
    useExisting: forwardRef(() => TuiTextfieldSizeDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldSizeDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiTextfieldSize]',
      providers: [{
        provide: TUI_TEXTFIELD_SIZE,
        useExisting: forwardRef(() => TuiTextfieldSizeDirective)
      }]
    }]
  }], null, {
    size: [{
      type: Input,
      args: ['tuiTextfieldSize']
    }]
  });
})();
class TuiTextfieldControllerModule {}
TuiTextfieldControllerModule.ɵfac = function TuiTextfieldControllerModule_Factory(t) {
  return new (t || TuiTextfieldControllerModule)();
};
TuiTextfieldControllerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiTextfieldControllerModule
});
TuiTextfieldControllerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldControllerModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiTextfieldAppearanceDirective, TuiTextfieldCleanerDirective, TuiTextfieldCustomContentDirective, TuiTextfieldLabelOutsideDirective, TuiTextfieldSizeDirective, TuiTextfieldIconDirective, TuiTextfieldIconLeftDirective, TuiTextfieldPrefixDirective, TuiTextfieldPostfixDirective, TuiTextfieldFillerDirective],
      exports: [TuiTextfieldAppearanceDirective, TuiTextfieldCleanerDirective, TuiTextfieldCustomContentDirective, TuiTextfieldLabelOutsideDirective, TuiTextfieldSizeDirective, TuiTextfieldIconDirective, TuiTextfieldIconLeftDirective, TuiTextfieldPrefixDirective, TuiTextfieldPostfixDirective, TuiTextfieldFillerDirective]
    }]
  }], null, null);
})();
const TUI_TEXTFIELD_WATCHED_CONTROLLER = new InjectionToken('[TUI_TEXTFIELD_WATCHED_CONTROLLER]');
const TEXTFIELD_CONTROLLER_PROVIDER = [TuiDestroyService, {
  provide: TUI_TEXTFIELD_WATCHED_CONTROLLER,
  deps: [ChangeDetectorRef, TuiDestroyService, TUI_TEXTFIELD_OPTIONS, TUI_TEXTFIELD_APPEARANCE, TUI_TEXTFIELD_APPEARANCE_DIRECTIVE, TUI_TEXTFIELD_CLEANER, TUI_TEXTFIELD_CUSTOM_CONTENT, TUI_TEXTFIELD_ICON, TUI_TEXTFIELD_ICON_LEFT, TUI_TEXTFIELD_LABEL_OUTSIDE, TUI_TEXTFIELD_SIZE, TUI_TEXTFIELD_PREFIX, TUI_TEXTFIELD_POSTFIX, TUI_TEXTFIELD_FILLER],
  useFactory: (cdr, destroy$, options, legacyAppearance, ...controllers) => {
    const change$ = merge(...controllers.map(({
      change$
    }) => change$ || NEVER)).pipe(tuiWatch(cdr), takeUntil(destroy$));
    change$.subscribe();
    return new TuiTextfieldController(change$, options, legacyAppearance, ...controllers);
  }
}];

/**
 * Generated bundle index. Do not edit.
 */

export { TEXTFIELD_CONTROLLER_PROVIDER, TUI_TEXTFIELD_APPEARANCE_DIRECTIVE, TUI_TEXTFIELD_CLEANER, TUI_TEXTFIELD_CUSTOM_CONTENT, TUI_TEXTFIELD_DEFAULT_OPTIONS, TUI_TEXTFIELD_FILLER, TUI_TEXTFIELD_ICON, TUI_TEXTFIELD_ICON_LEFT, TUI_TEXTFIELD_LABEL_OUTSIDE, TUI_TEXTFIELD_OPTIONS, TUI_TEXTFIELD_POSTFIX, TUI_TEXTFIELD_PREFIX, TUI_TEXTFIELD_SIZE, TUI_TEXTFIELD_WATCHED_CONTROLLER, TuiTextfieldAppearanceDirective, TuiTextfieldCleanerDirective, TuiTextfieldController, TuiTextfieldControllerModule, TuiTextfieldCustomContentDirective, TuiTextfieldFillerDirective, TuiTextfieldIconDirective, TuiTextfieldIconLeftDirective, TuiTextfieldLabelOutsideDirective, TuiTextfieldPostfixDirective, TuiTextfieldPrefixDirective, TuiTextfieldSizeDirective, tuiTextfieldOptionsProvider };
