import * as i0 from '@angular/core';
import { EventEmitter, Directive, Input, Output, ElementRef, INJECTOR, Self, Inject, Optional, SkipSelf, forwardRef, Component, ChangeDetectionStrategy, HostBinding, HostListener, ViewContainerRef, NgModule } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import * as i2 from '@taiga-ui/cdk';
import { tuiCreateTokenFromFactory, TuiDestroyService, TuiDropdownPortalService, tuiPure, tuiCreateToken, tuiProvideOptions, TuiHoveredService, EMPTY_CLIENT_RECT, tuiPx, tuiGetClosestFocusable, tuiPointToClientRect, TuiActiveZoneDirective, TUI_IS_IOS, TUI_IS_TOUCH, ALWAYS_TRUE_HANDLER, tuiIsTextNode, tuiIsString, tuiIsElement, tuiGetNativeFocused, tuiIsTextfield, CHAR_ZERO_WIDTH_SPACE, CHAR_NO_BREAK_SPACE, TUI_RANGE, TuiActiveZoneModule, TuiOverscrollModule, TuiHoveredModule } from '@taiga-ui/cdk';
import * as i1$1 from '@taiga-ui/core/abstract';
import { tuiAsRectAccessor, tuiAsVehicle, TuiDriver, tuiAsDriver, TuiPositionAccessor, tuiFallbackRectAccessor, TuiRectAccessor, tuiPositionAccessorFor, tuiRectAccessorFor, AbstractTuiDriverDirective, TuiVehicle, tuiAsPositionAccessor } from '@taiga-ui/core/abstract';
import { tuiDropdownAnimation } from '@taiga-ui/core/animations';
import { MODE_PROVIDER } from '@taiga-ui/core/providers';
import * as i4 from '@taiga-ui/core/services';
import { TuiVisualViewportService, TuiPositionService } from '@taiga-ui/core/services';
import { TUI_VIEWPORT, TUI_ANIMATION_OPTIONS, TUI_MODE, TUI_SELECTION_STREAM } from '@taiga-ui/core/tokens';
import { throttleTime, takeUntil, switchMap, delay, tap, share, map, distinctUntilChanged } from 'rxjs/operators';
import { __decorate } from 'tslib';
import { tuiCheckFixedPosition, tuiOverrideOptions, tuiGetWordRange } from '@taiga-ui/core/utils';
import * as i3 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusComponent, PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i1 from 'rxjs';
import { Subject, merge, of, BehaviorSubject, combineLatest } from 'rxjs';
import * as i1$2 from '@taiga-ui/core/components/scrollbar';
import { TuiScrollbarModule } from '@taiga-ui/core/components/scrollbar';
import { shouldCall } from '@tinkoff/ng-event-plugins';
import { DOCUMENT } from '@angular/common';

/**
 * A component to display a dropdown
 */
const _c0 = a0 => ({
  $implicit: a0
});
function TuiDropdownComponent_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const text_r2 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r2, " ");
  }
}
const TUI_DROPDOWN_COMPONENT = tuiCreateTokenFromFactory(() => TuiDropdownComponent);
class TuiDropdownOpenDirective {
  constructor() {
    this.tuiDropdownOpen = false;
    this.tuiDropdownOpenChange = new EventEmitter();
  }
  update(open) {
    this.tuiDropdownOpen = open;
    this.tuiDropdownOpenChange.emit(open);
  }
  ngOnChanges() {
    var _a;
    (_a = this.dropdown) === null || _a === void 0 ? void 0 : _a.toggle(this.tuiDropdownOpen);
  }
}
TuiDropdownOpenDirective.ɵfac = function TuiDropdownOpenDirective_Factory(t) {
  return new (t || TuiDropdownOpenDirective)();
};
TuiDropdownOpenDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownOpenDirective,
  selectors: [["", "tuiDropdownOpen", ""], ["", "tuiDropdownOpenChange", ""]],
  inputs: {
    tuiDropdownOpen: "tuiDropdownOpen"
  },
  outputs: {
    tuiDropdownOpenChange: "tuiDropdownOpenChange"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownOpenDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdownOpen],[tuiDropdownOpenChange]'
    }]
  }], null, {
    tuiDropdownOpen: [{
      type: Input
    }],
    tuiDropdownOpenChange: [{
      type: Output
    }]
  });
})();
class TuiDropdownDirective {
  constructor(destroy$, el, dropdown, injector, dropdownService, open) {
    this.el = el;
    this.dropdown = dropdown;
    this.injector = injector;
    this.dropdownService = dropdownService;
    this.open = open;
    this.refresh$ = new Subject();
    this.dropdownBoxRef = null;
    this.type = 'dropdown';
    this.component = new PolymorpheusComponent(this.dropdown, this.injector);
    if (this.open && !this.open.dropdown) {
      this.open.dropdown = this;
    } else {
      this.open = null;
    }
    // Ignore multiple change detection triggers at the same frame
    this.refresh$.pipe(throttleTime(0), takeUntil(destroy$)).subscribe(() => {
      var _a, _b;
      (_a = this.dropdownBoxRef) === null || _a === void 0 ? void 0 : _a.changeDetectorRef.detectChanges();
      (_b = this.dropdownBoxRef) === null || _b === void 0 ? void 0 : _b.changeDetectorRef.markForCheck();
    });
  }
  get position() {
    return tuiCheckFixedPosition(this.el.nativeElement) ? 'fixed' : 'absolute';
  }
  ngAfterViewChecked() {
    this.refresh$.next();
  }
  ngAfterViewInit() {
    if (this.open) {
      this.toggle(this.open.tuiDropdownOpen);
    }
  }
  ngOnChanges() {
    if (!this.content) {
      this.toggle(false);
    }
  }
  ngOnDestroy() {
    this.toggle(false);
    if (this.open) {
      this.open.dropdown = undefined;
    }
  }
  getClientRect() {
    return this.el.nativeElement.getBoundingClientRect();
  }
  toggle(show) {
    var _a, _b;
    if (show && this.content && !this.dropdownBoxRef) {
      this.dropdownBoxRef = this.dropdownService.add(this.component);
      (_a = this.open) === null || _a === void 0 ? void 0 : _a.update(true);
    } else if (!show && this.dropdownBoxRef) {
      this.dropdownService.remove(this.dropdownBoxRef);
      this.dropdownBoxRef = null;
      (_b = this.open) === null || _b === void 0 ? void 0 : _b.update(false);
    }
  }
}
TuiDropdownDirective.ɵfac = function TuiDropdownDirective_Factory(t) {
  return new (t || TuiDropdownDirective)(i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TUI_DROPDOWN_COMPONENT), i0.ɵɵdirectiveInject(INJECTOR), i0.ɵɵdirectiveInject(TuiDropdownPortalService), i0.ɵɵdirectiveInject(TuiDropdownOpenDirective, 8));
};
TuiDropdownDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownDirective,
  selectors: [["", "tuiDropdown", "", 5, "ng-container"]],
  inputs: {
    content: [0, "tuiDropdown", "content"]
  },
  exportAs: ["tuiDropdown"],
  features: [i0.ɵɵProvidersFeature([TuiDestroyService, tuiAsRectAccessor(TuiDropdownDirective), tuiAsVehicle(TuiDropdownDirective)]), i0.ɵɵNgOnChangesFeature]
});
__decorate([tuiPure], TuiDropdownDirective.prototype, "position", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdown]:not(ng-container)',
      providers: [TuiDestroyService, tuiAsRectAccessor(TuiDropdownDirective), tuiAsVehicle(TuiDropdownDirective)],
      exportAs: 'tuiDropdown'
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.Type,
      decorators: [{
        type: Inject,
        args: [TUI_DROPDOWN_COMPONENT]
      }]
    }, {
      type: i0.Injector,
      decorators: [{
        type: Inject,
        args: [INJECTOR]
      }]
    }, {
      type: i2.TuiDropdownPortalService,
      decorators: [{
        type: Inject,
        args: [TuiDropdownPortalService]
      }]
    }, {
      type: TuiDropdownOpenDirective,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TuiDropdownOpenDirective]
      }]
    }];
  }, {
    content: [{
      type: Input,
      args: ['tuiDropdown']
    }],
    position: []
  });
})();

/** Default values for hint options */
const TUI_DROPDOWN_HOVER_DEFAULT_OPTIONS = {
  showDelay: 200,
  hideDelay: 500
};
/**
 * Default parameters for dropdown hover directive
 */
const TUI_DROPDOWN_HOVER_OPTIONS = tuiCreateToken(TUI_DROPDOWN_HOVER_DEFAULT_OPTIONS);
function tuiDropdownHoverOptionsProvider(options) {
  return tuiProvideOptions(TUI_DROPDOWN_HOVER_OPTIONS, options, TUI_DROPDOWN_HOVER_DEFAULT_OPTIONS);
}
class TuiDropdownHoverDirective extends TuiDriver {
  constructor(hovered$, options, parentHover) {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.hovered$ = hovered$;
    this.options = options;
    this.parentHover = parentHover;
    this.toggle$ = new Subject();
    this.stream$ = merge(this.toggle$, this.hovered$).pipe(switchMap(visible => of(visible).pipe(delay(visible ? this.showDelay : this.hideDelay))), tap(visible => {
      this.hovered = visible;
    }), share());
    this.showDelay = this.options.showDelay;
    this.hideDelay = this.options.hideDelay;
    this.hovered = false;
    this.type = 'dropdown';
  }
  toggle(visible) {
    var _a;
    (_a = this.parentHover) === null || _a === void 0 ? void 0 : _a.toggle(visible);
    this.toggle$.next(visible);
  }
}
TuiDropdownHoverDirective.ɵfac = function TuiDropdownHoverDirective_Factory(t) {
  return new (t || TuiDropdownHoverDirective)(i0.ɵɵdirectiveInject(TuiHoveredService), i0.ɵɵdirectiveInject(TUI_DROPDOWN_HOVER_OPTIONS), i0.ɵɵdirectiveInject(TuiDropdownHoverDirective, 12));
};
TuiDropdownHoverDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownHoverDirective,
  selectors: [["", "tuiDropdownHover", "", 5, "ng-container"]],
  inputs: {
    showDelay: [0, "tuiDropdownShowDelay", "showDelay"],
    hideDelay: [0, "tuiDropdownHideDelay", "hideDelay"]
  },
  features: [i0.ɵɵProvidersFeature([tuiAsDriver(TuiDropdownHoverDirective), TuiHoveredService]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownHoverDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdownHover]:not(ng-container)',
      providers: [tuiAsDriver(TuiDropdownHoverDirective), TuiHoveredService]
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiHoveredService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DROPDOWN_HOVER_OPTIONS]
      }]
    }, {
      type: TuiDropdownHoverDirective,
      decorators: [{
        type: SkipSelf
      }, {
        type: Optional
      }, {
        type: Inject,
        args: [TuiDropdownHoverDirective]
      }]
    }];
  }, {
    showDelay: [{
      type: Input,
      args: ['tuiDropdownShowDelay']
    }],
    hideDelay: [{
      type: Input,
      args: ['tuiDropdownHideDelay']
    }]
  });
})();

/** Default values for dropdown options */
const TUI_DROPDOWN_DEFAULT_OPTIONS = {
  align: 'left',
  direction: null,
  limitWidth: 'auto',
  maxHeight: 400,
  minHeight: 80,
  offset: 4,
  appearance: ''
};
/**
 * Default parameters for dropdown directive
 */
const TUI_DROPDOWN_OPTIONS = tuiCreateToken(TUI_DROPDOWN_DEFAULT_OPTIONS);
const tuiDropdownOptionsProvider = override => ({
  provide: TUI_DROPDOWN_OPTIONS,
  deps: [[new Optional(), TuiDropdownOptionsDirective], [new Optional(), new SkipSelf(), TUI_DROPDOWN_OPTIONS]],
  useFactory: tuiOverrideOptions(override, TUI_DROPDOWN_DEFAULT_OPTIONS)
});
class TuiDropdownOptionsDirective {
  constructor(options) {
    this.options = options;
    this.align = this.options.align;
    this.appearance = this.options.appearance;
    this.direction = this.options.direction;
    this.limitWidth = this.options.limitWidth;
    this.minHeight = this.options.minHeight;
    this.maxHeight = this.options.maxHeight;
    this.offset = this.options.offset;
  }
}
TuiDropdownOptionsDirective.ɵfac = function TuiDropdownOptionsDirective_Factory(t) {
  return new (t || TuiDropdownOptionsDirective)(i0.ɵɵdirectiveInject(TUI_DROPDOWN_OPTIONS, 4));
};
TuiDropdownOptionsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownOptionsDirective,
  selectors: [["", "tuiDropdownAlign", ""], ["", "tuiDropdownAppearance", ""], ["", "tuiDropdownDirection", ""], ["", "tuiDropdownLimitWidth", ""], ["", "tuiDropdownMinHeight", ""], ["", "tuiDropdownMaxHeight", ""], ["", "tuiDropdownOffset", ""]],
  inputs: {
    align: [0, "tuiDropdownAlign", "align"],
    appearance: [0, "tuiDropdownAppearance", "appearance"],
    direction: [0, "tuiDropdownDirection", "direction"],
    limitWidth: [0, "tuiDropdownLimitWidth", "limitWidth"],
    minHeight: [0, "tuiDropdownMinHeight", "minHeight"],
    maxHeight: [0, "tuiDropdownMaxHeight", "maxHeight"],
    offset: [0, "tuiDropdownOffset", "offset"]
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: TUI_DROPDOWN_OPTIONS,
    useExisting: forwardRef(() => TuiDropdownOptionsDirective)
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownOptionsDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdownAlign], [tuiDropdownAppearance], [tuiDropdownDirection], [tuiDropdownLimitWidth], [tuiDropdownMinHeight], [tuiDropdownMaxHeight], [tuiDropdownOffset]',
      providers: [{
        provide: TUI_DROPDOWN_OPTIONS,
        useExisting: forwardRef(() => TuiDropdownOptionsDirective)
      }]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: SkipSelf
      }, {
        type: Inject,
        args: [TUI_DROPDOWN_OPTIONS]
      }]
    }];
  }, {
    align: [{
      type: Input,
      args: ['tuiDropdownAlign']
    }],
    appearance: [{
      type: Input,
      args: ['tuiDropdownAppearance']
    }],
    direction: [{
      type: Input,
      args: ['tuiDropdownDirection']
    }],
    limitWidth: [{
      type: Input,
      args: ['tuiDropdownLimitWidth']
    }],
    minHeight: [{
      type: Input,
      args: ['tuiDropdownMinHeight']
    }],
    maxHeight: [{
      type: Input,
      args: ['tuiDropdownMaxHeight']
    }],
    offset: [{
      type: Input,
      args: ['tuiDropdownOffset']
    }]
  });
})();
class TuiDropdownPositionDirective extends TuiPositionAccessor {
  constructor(options, viewport, accessors, directive) {
    super();
    this.options = options;
    this.viewport = viewport;
    this.accessors = accessors;
    this.directive = directive;
    this.type = 'dropdown';
  }
  getPosition({
    width,
    height
  }) {
    var _a, _b;
    if (!width && !height) {
      this.previous = undefined;
    }
    const hostRect = (_b = (_a = this.accessor) === null || _a === void 0 ? void 0 : _a.getClientRect()) !== null && _b !== void 0 ? _b : EMPTY_CLIENT_RECT;
    const viewportRect = this.viewport.getClientRect();
    const {
      minHeight,
      align,
      direction,
      offset,
      limitWidth
    } = this.options;
    const viewport = {
      top: viewportRect.top - offset,
      bottom: viewportRect.bottom + offset,
      right: viewportRect.right - offset,
      left: viewportRect.left + offset
    };
    const previous = this.previous || direction || 'bottom';
    const available = {
      top: hostRect.top - 2 * offset - viewport.top,
      bottom: viewport.bottom - hostRect.bottom - 2 * offset
    };
    const rectWidth = limitWidth === 'fixed' ? hostRect.width : width;
    const right = Math.max(hostRect.right - rectWidth, offset);
    const left = hostRect.left + width < viewport.right ? hostRect.left : right;
    const position = {
      top: hostRect.top - offset - height,
      bottom: hostRect.bottom + offset,
      right: Math.max(viewport.left, right),
      center: hostRect.left + hostRect.width / 2 + width / 2 < viewport.right ? hostRect.left + hostRect.width / 2 - width / 2 : right,
      left: Math.max(viewport.left, left)
    };
    const better = available.top > available.bottom ? 'top' : 'bottom';
    if (available[previous] > minHeight && direction || available[previous] > height) {
      return [position[previous], position[align]];
    }
    this.previous = better;
    return [position[better], position[align]];
  }
  get accessor() {
    return tuiFallbackRectAccessor('dropdown')(this.accessors, this.directive);
  }
}
TuiDropdownPositionDirective.ɵfac = function TuiDropdownPositionDirective_Factory(t) {
  return new (t || TuiDropdownPositionDirective)(i0.ɵɵdirectiveInject(TUI_DROPDOWN_OPTIONS), i0.ɵɵdirectiveInject(TUI_VIEWPORT), i0.ɵɵdirectiveInject(TuiRectAccessor), i0.ɵɵdirectiveInject(TuiDropdownDirective));
};
TuiDropdownPositionDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownPositionDirective,
  selectors: [["", "tuiDropdown", ""]],
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([tuiPure], TuiDropdownPositionDirective.prototype, "accessor", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownPositionDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdown]'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DROPDOWN_OPTIONS]
      }]
    }, {
      type: i1$1.TuiRectAccessor,
      decorators: [{
        type: Inject,
        args: [TUI_VIEWPORT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TuiRectAccessor]
      }]
    }, {
      type: TuiDropdownDirective,
      decorators: [{
        type: Inject,
        args: [TuiDropdownDirective]
      }]
    }];
  }, {
    accessor: []
  });
})();

/**
 * @description:
 * This component is used to show template in a portal
 * using default style of white rounded box with a shadow
 */
class TuiDropdownComponent {
  constructor(vvs, position$, destroy$, directive, animation, el, accessor, win, mode$, options, hoverDirective) {
    this.directive = directive;
    this.animation = animation;
    this.el = el;
    this.accessor = accessor;
    this.win = win;
    this.mode$ = mode$;
    this.options = options;
    this.hoverDirective = hoverDirective;
    position$.pipe(map(point => this.directive.position === 'fixed' ? vvs.correct(point) : point), takeUntil(destroy$)).subscribe(([top, left]) => {
      if (this.directive.el.nativeElement.isConnected) {
        this.update(top, left);
      } else {
        this.directive.toggle(false);
      }
    });
    this.updateWidth(this.accessor.getClientRect().width);
  }
  onHoveredChange(hovered) {
    var _a;
    (_a = this.hoverDirective) === null || _a === void 0 ? void 0 : _a.toggle(hovered);
  }
  onTopFocus() {
    this.moveFocusOutside(true);
  }
  onBottomFocus() {
    this.moveFocusOutside(false);
  }
  update(top, left) {
    var _a;
    const {
      style
    } = this.el.nativeElement;
    const {
      right
    } = this.el.nativeElement.getBoundingClientRect();
    const {
      maxHeight,
      minHeight,
      offset
    } = this.options;
    const {
      innerHeight
    } = this.win;
    const clientRect = (_a = this.el.nativeElement.offsetParent) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
    const {
      position
    } = this.directive;
    const rect = this.accessor.getClientRect();
    const offsetX = position === 'fixed' ? 0 : -((clientRect === null || clientRect === void 0 ? void 0 : clientRect.left) || 0);
    const offsetY = position === 'fixed' ? 0 : -((clientRect === null || clientRect === void 0 ? void 0 : clientRect.top) || 0);
    top += offsetY;
    left += offsetX;
    const isIntersecting = left < rect.right && right > rect.left && top < offsetY + 2 * offset;
    const available = isIntersecting ? rect.top - 2 * offset : offsetY + innerHeight - top - offset;
    const sided = right <= rect.left || left >= rect.right;
    style.position = position;
    style.top = tuiPx(Math.max(top, offsetY + offset));
    style.left = tuiPx(left);
    style.maxHeight = sided ? `${maxHeight}px` : tuiPx(Math.min(maxHeight, Math.max(available, minHeight)));
    style.width = '';
    style.minWidth = '';
    this.updateWidth(rect.width);
  }
  updateWidth(width) {
    const {
      style
    } = this.el.nativeElement;
    switch (this.options.limitWidth) {
      case 'min':
        style.minWidth = tuiPx(width);
        break;
      case 'fixed':
        style.width = tuiPx(width);
        break;
      case 'auto':
        break;
    }
  }
  moveFocusOutside(previous) {
    const {
      nativeElement
    } = this.directive.el;
    const {
      ownerDocument
    } = nativeElement;
    const root = ownerDocument ? ownerDocument.body : nativeElement;
    let focusable = tuiGetClosestFocusable({
      initial: nativeElement,
      root,
      previous
    });
    while (focusable !== null && nativeElement.contains(focusable)) {
      focusable = tuiGetClosestFocusable({
        initial: focusable,
        root,
        previous
      });
    }
    focusable === null || focusable === void 0 ? void 0 : focusable.focus();
  }
}
TuiDropdownComponent.ɵfac = function TuiDropdownComponent_Factory(t) {
  return new (t || TuiDropdownComponent)(i0.ɵɵdirectiveInject(TuiVisualViewportService), i0.ɵɵdirectiveInject(TuiPositionService), i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(TuiDropdownDirective), i0.ɵɵdirectiveInject(TUI_ANIMATION_OPTIONS), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TuiRectAccessor), i0.ɵɵdirectiveInject(WINDOW), i0.ɵɵdirectiveInject(TUI_MODE), i0.ɵɵdirectiveInject(TUI_DROPDOWN_OPTIONS), i0.ɵɵdirectiveInject(TuiDropdownHoverDirective, 8));
};
TuiDropdownComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiDropdownComponent,
  selectors: [["tui-dropdown"]],
  hostVars: 2,
  hostBindings: function TuiDropdownComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("$.data-mode.attr", function TuiDropdownComponent___data_mode_attr_HostBindingHandler() {
        return ctx.mode$;
      });
    }
    if (rf & 2) {
      i0.ɵɵsyntheticHostProperty("@tuiDropdownAnimation", ctx.animation);
      i0.ɵɵattribute("data-appearance", ctx.options.appearance);
    }
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService, TuiPositionService, tuiPositionAccessorFor('dropdown', TuiDropdownPositionDirective), tuiRectAccessorFor('dropdown', TuiDropdownDirective), MODE_PROVIDER])],
  decls: 5,
  vars: 4,
  consts: [["activeZone", "tuiActiveZone"], ["tuiActiveZone", "", "tuiOverscroll", "all", 1, "t-scroll", 3, "tuiHoveredChange"], ["tabindex", "0", 3, "focus"], ["class", "t-primitive", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], [1, "t-primitive"]],
  template: function TuiDropdownComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "tui-scrollbar", 1, 0);
      i0.ɵɵlistener("tuiHoveredChange", function TuiDropdownComponent_Template_tui_scrollbar_tuiHoveredChange_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onHoveredChange($event));
      });
      i0.ɵɵelementStart(2, "div", 2);
      i0.ɵɵlistener("focus", function TuiDropdownComponent_Template_div_focus_2_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onTopFocus());
      });
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(3, TuiDropdownComponent_div_3_Template, 2, 1, "div", 3);
      i0.ɵɵelementStart(4, "div", 2);
      i0.ɵɵlistener("focus", function TuiDropdownComponent_Template_div_focus_4_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onBottomFocus());
      });
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      const activeZone_r3 = i0.ɵɵreference(1);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("polymorpheusOutlet", ctx.directive.content)("polymorpheusOutletContext", i0.ɵɵpureFunction1(2, _c0, activeZone_r3));
    }
  },
  dependencies: [i1$2.TuiScrollbarComponent, i2.TuiActiveZoneDirective, i2.TuiOverscrollDirective, i2.TuiHoveredDirective, i3.PolymorpheusOutletDirective],
  styles: ["[_nghost-%COMP%]{position:absolute;display:flex;box-shadow:var(--tui-shadow-dropdown);background:var(--tui-elevation-02);border-radius:var(--tui-radius-m);overflow:hidden;border:1px solid var(--tui-base-04);box-sizing:border-box;max-width:calc(100% - 8px);isolation:isolate;pointer-events:auto}.ng-animating[_nghost-%COMP%]{pointer-events:none}[_nghost-%COMP%]:not([style*=\"top\"]){visibility:hidden}[data-mode=onDark][_nghost-%COMP%]{--tui-text-01: var(--tui-text-01-night);--tui-clear: var(--tui-clear-inverse);background:#222;border:1px solid #808080}.t-scroll[_ngcontent-%COMP%]{flex-grow:1;max-width:100%}.t-primitive[_ngcontent-%COMP%]{padding:1rem}"],
  data: {
    animation: [tuiDropdownAnimation]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownComponent, [{
    type: Component,
    args: [{
      selector: 'tui-dropdown',
      templateUrl: './dropdown.template.html',
      styleUrls: ['./dropdown.style.less'],
      // @bad TODO: OnPush
      // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
      changeDetection: ChangeDetectionStrategy.Default,
      providers: [TuiDestroyService, TuiPositionService, tuiPositionAccessorFor('dropdown', TuiDropdownPositionDirective), tuiRectAccessorFor('dropdown', TuiDropdownDirective), MODE_PROVIDER],
      host: {
        '[@tuiDropdownAnimation]': 'animation',
        '[attr.data-appearance]': 'options.appearance',
        '($.data-mode.attr)': 'mode$'
      },
      animations: [tuiDropdownAnimation]
    }]
  }], function () {
    return [{
      type: i4.TuiVisualViewportService,
      decorators: [{
        type: Inject,
        args: [TuiVisualViewportService]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiPositionService]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: TuiDropdownDirective,
      decorators: [{
        type: Inject,
        args: [TuiDropdownDirective]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ANIMATION_OPTIONS]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i1$1.TuiRectAccessor,
      decorators: [{
        type: Inject,
        args: [TuiRectAccessor]
      }]
    }, {
      type: Window,
      decorators: [{
        type: Inject,
        args: [WINDOW]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_MODE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DROPDOWN_OPTIONS]
      }]
    }, {
      type: TuiDropdownHoverDirective,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TuiDropdownHoverDirective]
      }]
    }];
  }, null);
})();
function activeZoneFilter(target) {
  return !this.activeZone.contains(target);
}
const TAP_DELAY = 700;
const MOVE_THRESHOLD = 15;
class TuiDropdownContextDirective extends TuiDriver {
  constructor(activeZone, isIOS, isTouch) {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.activeZone = activeZone;
    this.isIOS = isIOS;
    this.isTouch = isTouch;
    this.stream$ = new Subject();
    this.currentRect = EMPTY_CLIENT_RECT;
    this.longTapTimeout = NaN;
    this.type = 'dropdown';
  }
  get userSelect() {
    return this.isTouch ? 'none' : null;
  }
  onContextMenu(x, y) {
    this.currentRect = tuiPointToClientRect(x, y);
    this.stream$.next(true);
  }
  closeDropdown() {
    this.stream$.next(false);
    this.currentRect = EMPTY_CLIENT_RECT;
  }
  onTouchMove(x, y) {
    if (this.isIOS && this.isTouch && this.currentRect !== EMPTY_CLIENT_RECT && Math.hypot(x - this.currentRect.x, y - this.currentRect.y) > MOVE_THRESHOLD) {
      this.onTouchEnd();
    }
  }
  onTouchStart(x, y) {
    if (!this.isIOS || !this.isTouch || this.currentRect !== EMPTY_CLIENT_RECT) {
      return;
    }
    this.currentRect = tuiPointToClientRect(x, y);
    this.longTapTimeout = setTimeout(() => {
      this.stream$.next(true);
    }, TAP_DELAY);
  }
  onTouchEnd() {
    if (this.isIOS && this.isTouch) {
      clearTimeout(this.longTapTimeout);
    }
  }
  getClientRect() {
    return this.currentRect;
  }
}
TuiDropdownContextDirective.ɵfac = function TuiDropdownContextDirective_Factory(t) {
  return new (t || TuiDropdownContextDirective)(i0.ɵɵdirectiveInject(TuiActiveZoneDirective), i0.ɵɵdirectiveInject(TUI_IS_IOS), i0.ɵɵdirectiveInject(TUI_IS_TOUCH));
};
TuiDropdownContextDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownContextDirective,
  selectors: [["", "tuiDropdown", "", "tuiDropdownContext", ""]],
  hostVars: 6,
  hostBindings: function TuiDropdownContextDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("contextmenu.prevent.stop", function TuiDropdownContextDirective_contextmenu_prevent_stop_HostBindingHandler($event) {
        return ctx.onContextMenu($event.clientX, $event.clientY);
      })("click.silent", function TuiDropdownContextDirective_click_silent_HostBindingHandler($event) {
        return ctx.closeDropdown($event.target);
      }, false, i0.ɵɵresolveDocument)("contextmenu.capture.silent", function TuiDropdownContextDirective_contextmenu_capture_silent_HostBindingHandler($event) {
        return ctx.closeDropdown($event.target);
      }, false, i0.ɵɵresolveDocument)("keydown.esc", function TuiDropdownContextDirective_keydown_esc_HostBindingHandler($event) {
        return ctx.closeDropdown($event.currentTarget);
      }, false, i0.ɵɵresolveDocument)("touchmove.silent.passive", function TuiDropdownContextDirective_touchmove_silent_passive_HostBindingHandler($event) {
        return ctx.onTouchMove($event.touches[0].clientX, $event.touches[0].clientY);
      })("touchstart.silent.passive", function TuiDropdownContextDirective_touchstart_silent_passive_HostBindingHandler($event) {
        return ctx.onTouchStart($event.touches[0].clientX, $event.touches[0].clientY);
      })("touchend.silent.passive", function TuiDropdownContextDirective_touchend_silent_passive_HostBindingHandler() {
        return ctx.onTouchEnd();
      })("touchcancel.silent.passive", function TuiDropdownContextDirective_touchcancel_silent_passive_HostBindingHandler() {
        return ctx.onTouchEnd();
      });
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("user-select", ctx.userSelect)("-webkit-touch-callout", ctx.userSelect)("-webkit-user-select", ctx.userSelect);
    }
  },
  features: [i0.ɵɵProvidersFeature([TuiActiveZoneDirective, tuiAsDriver(TuiDropdownContextDirective), tuiAsRectAccessor(TuiDropdownContextDirective)]), i0.ɵɵInheritDefinitionFeature]
});
__decorate([shouldCall(activeZoneFilter)], TuiDropdownContextDirective.prototype, "closeDropdown", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownContextDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdown][tuiDropdownContext]',
      providers: [TuiActiveZoneDirective, tuiAsDriver(TuiDropdownContextDirective), tuiAsRectAccessor(TuiDropdownContextDirective)]
    }]
  }], function () {
    return [{
      type: i2.TuiActiveZoneDirective,
      decorators: [{
        type: Inject,
        args: [TuiActiveZoneDirective]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_IOS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_TOUCH]
      }]
    }];
  }, {
    userSelect: [{
      type: HostBinding,
      args: ['style.user-select']
    }, {
      type: HostBinding,
      args: ['style.-webkit-touch-callout']
    }, {
      type: HostBinding,
      args: ['style.-webkit-user-select']
    }],
    onContextMenu: [{
      type: HostListener,
      args: ['contextmenu.prevent.stop', ['$event.clientX', '$event.clientY']]
    }],
    closeDropdown: [{
      type: HostListener,
      args: ['document:click.silent', ['$event.target']]
    }, {
      type: HostListener,
      args: ['document:contextmenu.capture.silent', ['$event.target']]
    }, {
      type: HostListener,
      args: ['document:keydown.esc', ['$event.currentTarget']]
    }],
    onTouchMove: [{
      type: HostListener,
      args: ['touchmove.silent.passive', ['$event.touches[0].clientX', '$event.touches[0].clientY']]
    }],
    onTouchStart: [{
      type: HostListener,
      args: ['touchstart.silent.passive', ['$event.touches[0].clientX', '$event.touches[0].clientY']]
    }],
    onTouchEnd: [{
      type: HostListener,
      args: ['touchend.silent.passive']
    }, {
      type: HostListener,
      args: ['touchcancel.silent.passive']
    }]
  });
})();
class TuiDropdownDriverDirective extends AbstractTuiDriverDirective {
  // TODO: Figure out why this is necessary under nx test runner
  constructor(destroy$, drivers, vehicles) {
    super(destroy$, drivers, vehicles);
    this.type = 'dropdown';
  }
}
TuiDropdownDriverDirective.ɵfac = function TuiDropdownDriverDirective_Factory(t) {
  return new (t || TuiDropdownDriverDirective)(i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(TuiDriver), i0.ɵɵdirectiveInject(TuiVehicle));
};
TuiDropdownDriverDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownDriverDirective,
  selectors: [["", "tuiDropdown", ""]],
  features: [i0.ɵɵProvidersFeature([TuiDestroyService]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownDriverDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdown]',
      providers: [TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TuiDriver]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TuiVehicle]
      }]
    }];
  }, null);
})();
class TuiDropdownHostDirective extends TuiRectAccessor {
  constructor() {
    super(...arguments);
    this.type = 'dropdown';
  }
  getClientRect() {
    var _a;
    return ((_a = this.tuiDropdownHost) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) || EMPTY_CLIENT_RECT;
  }
}
TuiDropdownHostDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiDropdownHostDirective_BaseFactory;
  return function TuiDropdownHostDirective_Factory(t) {
    return (ɵTuiDropdownHostDirective_BaseFactory || (ɵTuiDropdownHostDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiDropdownHostDirective)))(t || TuiDropdownHostDirective);
  };
})();
TuiDropdownHostDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownHostDirective,
  selectors: [["", "tuiDropdown", "", "tuiDropdownHost", ""]],
  inputs: {
    tuiDropdownHost: "tuiDropdownHost"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsRectAccessor(TuiDropdownHostDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownHostDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdown][tuiDropdownHost]',
      providers: [tuiAsRectAccessor(TuiDropdownHostDirective)]
    }]
  }], null, {
    tuiDropdownHost: [{
      type: Input
    }]
  });
})();
class TuiDropdownManualDirective extends TuiDriver {
  constructor() {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.stream$ = new BehaviorSubject(false);
    this.tuiDropdownManual = false;
    this.type = 'dropdown';
  }
  ngOnChanges() {
    this.stream$.next(this.tuiDropdownManual);
  }
}
TuiDropdownManualDirective.ɵfac = function TuiDropdownManualDirective_Factory(t) {
  return new (t || TuiDropdownManualDirective)();
};
TuiDropdownManualDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownManualDirective,
  selectors: [["", "tuiDropdown", "", "tuiDropdownManual", ""]],
  inputs: {
    tuiDropdownManual: "tuiDropdownManual"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsDriver(TuiDropdownManualDirective)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownManualDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdown][tuiDropdownManual]',
      providers: [tuiAsDriver(TuiDropdownManualDirective)]
    }]
  }], function () {
    return [];
  }, {
    tuiDropdownManual: [{
      type: Input
    }]
  });
})();
class TuiDropdownPositionSidedDirective extends TuiPositionAccessor {
  constructor(options, viewport, vertical) {
    super();
    this.options = options;
    this.viewport = viewport;
    this.vertical = vertical;
    this.previous = this.options.direction || 'bottom';
    this.tuiDropdownSided = '';
    this.tuiDropdownSidedOffset = 4;
    this.type = 'dropdown';
  }
  getPosition(rect) {
    var _a, _b;
    if (this.tuiDropdownSided === false) {
      return this.vertical.getPosition(rect);
    }
    const {
      height,
      width
    } = rect;
    const hostRect = (_b = (_a = this.vertical.accessor) === null || _a === void 0 ? void 0 : _a.getClientRect()) !== null && _b !== void 0 ? _b : EMPTY_CLIENT_RECT;
    const viewport = this.viewport.getClientRect();
    const {
      direction,
      minHeight,
      offset
    } = this.options;
    const align = this.options.align === 'center' ? 'left' : this.options.align;
    const available = {
      top: hostRect.bottom - viewport.top,
      left: hostRect.left - offset - viewport.left,
      right: viewport.right - hostRect.right - offset,
      bottom: viewport.bottom - hostRect.top
    };
    const position = {
      top: hostRect.bottom - height + this.tuiDropdownSidedOffset + 1,
      left: hostRect.left - width - offset,
      right: hostRect.right + offset,
      bottom: hostRect.top - this.tuiDropdownSidedOffset - 1 // 1 for border
    };
    const better = available.top > available.bottom ? 'top' : 'bottom';
    const maxLeft = available.left > available.right ? position.left : position.right;
    const left = available[align] > width ? position[align] : maxLeft;
    if (available[this.previous] > minHeight && direction || this.previous === better) {
      return [position[this.previous], left];
    }
    this.previous = better;
    return [position[better], left];
  }
}
TuiDropdownPositionSidedDirective.ɵfac = function TuiDropdownPositionSidedDirective_Factory(t) {
  return new (t || TuiDropdownPositionSidedDirective)(i0.ɵɵdirectiveInject(TUI_DROPDOWN_OPTIONS), i0.ɵɵdirectiveInject(TUI_VIEWPORT), i0.ɵɵdirectiveInject(TuiDropdownPositionDirective));
};
TuiDropdownPositionSidedDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownPositionSidedDirective,
  selectors: [["", "tuiDropdownSided", ""]],
  inputs: {
    tuiDropdownSided: "tuiDropdownSided",
    tuiDropdownSidedOffset: "tuiDropdownSidedOffset"
  },
  features: [i0.ɵɵProvidersFeature([TuiDropdownPositionDirective, tuiAsPositionAccessor(TuiDropdownPositionSidedDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownPositionSidedDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdownSided]',
      providers: [TuiDropdownPositionDirective, tuiAsPositionAccessor(TuiDropdownPositionSidedDirective)]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DROPDOWN_OPTIONS]
      }]
    }, {
      type: i1$1.TuiRectAccessor,
      decorators: [{
        type: Inject,
        args: [TUI_VIEWPORT]
      }]
    }, {
      type: TuiDropdownPositionDirective,
      decorators: [{
        type: Inject,
        args: [TuiDropdownPositionDirective]
      }]
    }];
  }, {
    tuiDropdownSided: [{
      type: Input
    }],
    tuiDropdownSidedOffset: [{
      type: Input
    }]
  });
})();
class TuiDropdownSelectionDirective extends TuiDriver {
  constructor(range, doc, selection$, el, vcr, dropdown) {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.range = range;
    this.doc = doc;
    this.selection$ = selection$;
    this.el = el;
    this.vcr = vcr;
    this.dropdown = dropdown;
    this.handler$ = new BehaviorSubject(ALWAYS_TRUE_HANDLER);
    this.stream$ = combineLatest([this.handler$, this.selection$.pipe(map(() => this.getRange()), distinctUntilChanged((x, y) => x.startOffset === y.startOffset && x.endOffset === y.endOffset && x.commonAncestorContainer === y.commonAncestorContainer))]).pipe(map(([handler, range]) => {
      const contained = this.el.nativeElement.contains(range.commonAncestorContainer);
      this.range = contained && tuiIsTextNode(range.commonAncestorContainer) ? range : this.range;
      return contained && handler(this.range) || this.inDropdown(range);
    }));
    this.position = 'selection';
    this.type = 'dropdown';
  }
  set tuiDropdownSelection(visible) {
    if (!tuiIsString(visible)) {
      this.handler$.next(visible);
    }
  }
  getClientRect() {
    switch (this.position) {
      case 'tag':
        {
          const {
            commonAncestorContainer
          } = this.range;
          const element = tuiIsElement(commonAncestorContainer) ? commonAncestorContainer : commonAncestorContainer.parentNode;
          return element && tuiIsElement(element) ? element.getBoundingClientRect() : EMPTY_CLIENT_RECT;
        }
      case 'word':
        return tuiGetWordRange(this.range).getBoundingClientRect();
      default:
        return this.range.getBoundingClientRect();
    }
  }
  ngOnDestroy() {
    if (this.ghost) {
      this.vcr.element.nativeElement.removeChild(this.ghost);
    }
  }
  getRange() {
    const active = tuiGetNativeFocused(this.doc);
    const selection = this.doc.getSelection();
    const range = active && tuiIsTextfield(active) && this.el.nativeElement.contains(active) ? this.veryVerySadInputFix(active) : (selection === null || selection === void 0 ? void 0 : selection.rangeCount) && selection.getRangeAt(0) || this.range;
    return range.cloneRange();
  }
  /**
   * Check if given range is at least partially inside dropdown
   */
  inDropdown(range) {
    const {
      startContainer,
      endContainer
    } = range;
    const {
      nativeElement
    } = this.el;
    const inDropdown = this.boxContains(range.commonAncestorContainer);
    const hostToDropdown = this.boxContains(endContainer) && nativeElement.contains(startContainer);
    const dropdownToHost = this.boxContains(startContainer) && nativeElement.contains(endContainer);
    return inDropdown || hostToDropdown || dropdownToHost;
  }
  veryVerySadInputFix(element) {
    const {
      ghost = this.initGhost(element)
    } = this;
    const {
      top,
      left,
      width,
      height
    } = element.getBoundingClientRect();
    const {
      selectionStart,
      selectionEnd,
      value
    } = element;
    const range = this.doc.createRange();
    const hostRect = this.el.nativeElement.getBoundingClientRect();
    ghost.style.top = tuiPx(top - hostRect.top);
    ghost.style.left = tuiPx(left - hostRect.left);
    ghost.style.width = tuiPx(width);
    ghost.style.height = tuiPx(height);
    ghost.textContent = CHAR_ZERO_WIDTH_SPACE + value + CHAR_NO_BREAK_SPACE;
    range.setStart(ghost.firstChild, selectionStart || 0);
    range.setEnd(ghost.firstChild, selectionEnd || 0);
    return range;
  }
  /**
   * Check if Node is inside dropdown
   */
  boxContains(node) {
    var _a;
    return !!((_a = this.dropdown.dropdownBoxRef) === null || _a === void 0 ? void 0 : _a.location.nativeElement.contains(node));
  }
  /**
   * Create an invisible DIV styled exactly like input/textarea element inside directive
   */
  initGhost(element) {
    const ghost = this.doc.createElement('div');
    const {
      font,
      letterSpacing,
      textTransform,
      padding
    } = getComputedStyle(element);
    ghost.style.position = 'absolute';
    ghost.style.pointerEvents = 'none';
    ghost.style.opacity = '0';
    ghost.style.whiteSpace = 'pre-wrap';
    ghost.style.font = font;
    ghost.style.letterSpacing = letterSpacing;
    ghost.style.textTransform = textTransform;
    ghost.style.padding = padding;
    this.vcr.element.nativeElement.appendChild(ghost);
    this.ghost = ghost;
    return ghost;
  }
}
TuiDropdownSelectionDirective.ɵfac = function TuiDropdownSelectionDirective_Factory(t) {
  return new (t || TuiDropdownSelectionDirective)(i0.ɵɵdirectiveInject(TUI_RANGE), i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(TUI_SELECTION_STREAM), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(ViewContainerRef), i0.ɵɵdirectiveInject(TuiDropdownDirective));
};
TuiDropdownSelectionDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiDropdownSelectionDirective,
  selectors: [["", "tuiDropdown", "", "tuiDropdownSelection", ""]],
  inputs: {
    position: [0, "tuiDropdownSelectionPosition", "position"],
    tuiDropdownSelection: "tuiDropdownSelection"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsDriver(TuiDropdownSelectionDirective), tuiAsRectAccessor(TuiDropdownSelectionDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownSelectionDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiDropdown][tuiDropdownSelection]',
      providers: [tuiAsDriver(TuiDropdownSelectionDirective), tuiAsRectAccessor(TuiDropdownSelectionDirective)]
    }]
  }], function () {
    return [{
      type: Range,
      decorators: [{
        type: Inject,
        args: [TUI_RANGE]
      }]
    }, {
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_SELECTION_STREAM]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.ViewContainerRef,
      decorators: [{
        type: Inject,
        args: [ViewContainerRef]
      }]
    }, {
      type: TuiDropdownDirective,
      decorators: [{
        type: Inject,
        args: [TuiDropdownDirective]
      }]
    }];
  }, {
    position: [{
      type: Input,
      args: ['tuiDropdownSelectionPosition']
    }],
    tuiDropdownSelection: [{
      type: Input
    }]
  });
})();
class TuiDropdownModule {}
TuiDropdownModule.ɵfac = function TuiDropdownModule_Factory(t) {
  return new (t || TuiDropdownModule)();
};
TuiDropdownModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiDropdownModule
});
TuiDropdownModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[PolymorpheusModule, TuiActiveZoneModule, TuiOverscrollModule, TuiScrollbarModule, TuiHoveredModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDropdownModule, [{
    type: NgModule,
    args: [{
      imports: [PolymorpheusModule, TuiActiveZoneModule, TuiOverscrollModule, TuiScrollbarModule, TuiHoveredModule],
      declarations: [TuiDropdownDirective, TuiDropdownComponent, TuiDropdownOpenDirective, TuiDropdownOptionsDirective, TuiDropdownHostDirective, TuiDropdownDriverDirective, TuiDropdownManualDirective, TuiDropdownHoverDirective, TuiDropdownContextDirective, TuiDropdownPositionDirective, TuiDropdownPositionSidedDirective, TuiDropdownSelectionDirective],
      exports: [TuiDropdownDirective, TuiDropdownComponent, TuiDropdownOpenDirective, TuiDropdownOptionsDirective, TuiDropdownHostDirective, TuiDropdownDriverDirective, TuiDropdownManualDirective, TuiDropdownHoverDirective, TuiDropdownContextDirective, TuiDropdownPositionDirective, TuiDropdownPositionSidedDirective, TuiDropdownSelectionDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_DROPDOWN_COMPONENT, TUI_DROPDOWN_DEFAULT_OPTIONS, TUI_DROPDOWN_HOVER_DEFAULT_OPTIONS, TUI_DROPDOWN_HOVER_OPTIONS, TUI_DROPDOWN_OPTIONS, TuiDropdownComponent, TuiDropdownContextDirective, TuiDropdownDirective, TuiDropdownDriverDirective, TuiDropdownHostDirective, TuiDropdownHoverDirective, TuiDropdownManualDirective, TuiDropdownModule, TuiDropdownOpenDirective, TuiDropdownOptionsDirective, TuiDropdownPositionDirective, TuiDropdownPositionSidedDirective, TuiDropdownSelectionDirective, tuiDropdownHoverOptionsProvider, tuiDropdownOptionsProvider };
